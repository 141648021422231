const products = [
  {
    id: "virtual_art",
    path: "visual_arts_works",
    name: "VA Visual Arts Works",
    image: "/images/va/VA1.jpeg",
    title: `Visual Arts fields`,
    li1: "Photography",
    li2: "Drawings",
    li3: "Architectural blueprints",
    li4: "and like.",
  },
  {
    id: "literary_work",
    path: "literary_works",
    name: "TX Literary Works",
    image: "/images/tx/TX1.jpeg",
    title: "Literary Arts field",
    li1: "fiction , nonfiction",
    li2: "Poetry, catalogs",
    li3: "Compilations of information",
    li4: "Computer programs",
  },
  {
    id: "performing_art",
    path: "performing_arts_works",
    name: "PA Performing Arts",
    image: "/images/PA/PA1.jpeg",
    title: "Performing Arts fields",
    li1: "Musical works",
    li2: "Dramatic works",
    li3: "Pantomimes works",
    li4: "Motion pictures",
  },
  {
    id: "serial_work",
    path: "serial_work",
    name: "SE Serial Works",
    image: "/images/SE/SE1.jpeg",
    title: "Serial works fields",
    li1: "Newspapers",
    li2: "Annuals",
    li3: "The Journals",
    li4: "Transactions of societies",
    // periodicals; newspapers; annuals; and the journals, proceedings, and transactions of societies.
  },
  {
    id: "sound_recording",
    path: "sound_recording_works",
    name: "SR Sound Recording",
    image: "/images/SR/SR1.jpeg",
    title: "Sound Recordings arts",
    li1: "With one exception",
    li2: "Works that result from the fixation of a series of musical",
    li3: "Spoken or other sounds",
  },
  {
    path: "form_six",
    name: "MC Miscellaneous",
    image: "/images/WordArtmisc.jpeg",
    title: "Visual Arts field",
    li1: "Photography",
    li2: "Drawings",
    li3: "Architectural blueprints",
    li4: " and like.",
  },
];

export const carousalProducts = [
  {
    name: "Mouse",
    image: "/images/slider/Home1.jpeg",
  },
  {
    name: "Mouse",
    image: "/images/slider/Home2.jpeg",
  },
  {
    name: "Mouse",
    image: "/images/slider/Home3.jpeg",
  },
];

export default products;
